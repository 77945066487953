
import { Options, Vue } from 'vue-class-component'
import rankingsMain from '@/components/Frontend/RankingsNavigation/index.vue'

@Options({
  components: {
    rankingsMain
  }
})

export default class Rankingmain extends Vue {}
